import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {path: 'tab1',loadChildren: () => import('./tab1/tab1.module').then( m => m.Tab1PageModule)},
  {path: 'tab2',loadChildren: () => import('./tab2/tab2.module').then( m => m.Tab2PageModule)},
  {path: 'tab3',loadChildren: () => import('./tab3/tab3.module').then( m => m.Tab3PageModule)},
  {path: '',redirectTo: 'tab1',pathMatch: 'full'},
  {
    path: '',
    redirectTo: 'tab1',
    pathMatch: 'full'
  },
  //{path: '',loadChildren: () => import('./tabs/tabs.module').then( m => m.TabsPageModule)},  
  
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },  
  {
    path: 'comprobante',
    loadChildren: () => import('./modals/comprobante/comprobante.module').then( m => m.ComprobantePageModule)
  },
  {
    path: 'masinfo',
    loadChildren: () => import('./modals/masinfo/masinfo.module').then( m => m.MasinfoPageModule)
  },
  {
    path: 'registro',
    loadChildren: () => import('./registro/registro.module').then( m => m.RegistroPageModule)
  },  
  {
    path: 'olvido',
    loadChildren: () => import('./olvido/olvido.module').then( m => m.OlvidoPageModule)
  },
  {
    path: 'checkout',
    loadChildren: () => import('./modals/checkout/checkout.module').then( m => m.CheckoutPageModule)
  },
  {
    path: 'perfil',
    loadChildren: () => import('./modals/perfil/perfil.module').then( m => m.PerfilPageModule)
  },
  {
    path: 'orden',
    loadChildren: () => import('./modals/orden/orden.module').then( m => m.OrdenPageModule)
  },
  {
    path: 'favoritos',
    loadChildren: () => import('./modals/favoritos/favoritos.module').then( m => m.FavoritosPageModule)
  },
  {
    path: 'resultados',
    loadChildren: () => import('./modals/resultados/resultados.module').then( m => m.ResultadosPageModule)
  },
  {
    path: 'terminos',
    loadChildren: () => import('./modals/terminos/terminos.module').then( m => m.TerminosPageModule)
  }


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules,useHash: true  })
  ], 
  exports: [RouterModule]
})
export class AppRoutingModule { }
